import createFocusTrap from 'focus-trap';

export class Navigation {
  constructor() {
    this.nav = document.querySelector('.main-nav');
    this.toggleBtn = this.nav.querySelector('.main-nav__hamburger-button');
    this.toggleBtn.addEventListener('click', () => this.toggleMenu());
    // this.isOpen = false;
    // this.activeSubmenuContainer = null;
    // this.selectedMenuItems = [];
    
    // this.submenuHeader = document.querySelector('.main-nav__submenu-header');
    
    // this.focusTrap = createFocusTrap(this.nav);
    // 
    // this.initSubMenuButtons();
    // this.initCloseButton();
  }

  toggleMenu(force) {
    this.isOpen = typeof force === 'boolean' ? force : !this.isOpen;
    document.body.classList.toggle(
      'page-body--modal-is-showed', 
      this.isOpen
    );

    
    this.nav.classList.toggle(
      'main-nav--is-open', 
      this.isOpen
    );

    this.toggleBtn.classList.toggle(
      'main-nav__hamburger-button--active',
      this.isOpen
    );

    // this.submenuHeader.classList.toggle(
    //   'main-nav__submenu-header--active',
    //   this.isOpen
    // );


  //   this.toggleBtn.setAttribute('aria-expanded', String(this.isOpen));

  //   if (this.isOpen) {
  //     this.focusTrap.activate();
  //   } else {
  //     this.focusTrap.deactivate();
  //   }

    }
}
