import { defineComponent }  from 'vue';
// import './style/index.css'

export const FindRental = defineComponent({
    name: 'FindRental',
    components: {
    },
        setup() {   
              return () => (
                <h2>111</h2>
              )
        }
    });

export type FindRental = InstanceType<typeof FindRental>
