import { ContactUs } from '../../blocks/common/components/contact-us';
import { Navigation } from '../../blocks/common/components/nav';
import { FindRentalApplication } from '../../blocks/common/applications/find-rental-application'

class Main {
  nav = null;
  contactUs = null;
  findRentalApplication = null;

  async init() {
    this.nav = new Navigation();

    this.findRentalApplication = new FindRentalApplication();
    this.findRentalApplication.init({
      selector: '.find-rental'
    })
  }
}

const application = new Main();

application.init();
window['application'] = application;
