import { createApp } from 'vue';
import { FindRental } from './components/find-rental';

export class FindRentalApplication {
    async init({ 
        store, 
        selector
    }) {
        const app = createApp(FindRental);
        app.use(store);
        app.mount(selector);
    }
}